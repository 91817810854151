// AITabs.js
import React from 'react';
import PropTypes from 'prop-types';
import {
    MessageSquare,
    Cloud,
    Sliders,
    BarChart2
} from 'lucide-react';
import { Button } from '../../../../App/components/ui/button.jsx';
import { PANEL_TYPES } from '../../../constants/constants.js';
import './Tabs.css';

const AITabs = ({
                    activeTab,
                    setActiveTab,
                    tokenBalance = undefined
                }) => {
    const TABS = [
        {
            id: PANEL_TYPES.ANALYTICS,
            icon: BarChart2,
            tooltip: 'View usage analytics'
        },
        {
            id: PANEL_TYPES.INPUT,
            icon: MessageSquare,
            tooltip: 'Write or edit content'
        },
        {
            id: PANEL_TYPES.RESPONSES,
            icon: Cloud,
            tooltip: 'Compare AI responses'
        },
        {
            id: PANEL_TYPES.CHARACTERISTICS,
            icon: Sliders,
            tooltip: 'Adjust AI behavior'
        }
    ];

    return (
        <div className="tabs-container ai-tabs">
            {TABS.map(({ id, icon: Icon, tooltip }) => (
                <Button
                    key={id}
                    variant={activeTab === id ? 'default' : 'ghost'}
                    size="icon"
                    onClick={() => setActiveTab(id)}
                    className="tab-button"
                    title={tooltip}
                >
                    <Icon className="w-5 h-5" />
                </Button>
            ))}
            {tokenBalance !== undefined && (
                <div className="token-balance">
                    {tokenBalance}
                </div>
            )}
        </div>
    );
};

AITabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    tokenBalance: PropTypes.number,
};

export default AITabs;