// RegularTabs.js
import React from 'react';
import PropTypes from 'prop-types';
import {
    Type,
    Cpu,
    Share2,
} from 'lucide-react';
import { Button } from '../../../../App/components/ui/button.jsx';
import { PANEL_TYPES } from '../../../constants/constants.js';
import './Tabs.css';

const RegularTabs = ({
                         activeTab,
                         setActiveTab,
                     }) => {
    const TABS = [
        {
            id: PANEL_TYPES.WRITING,
            icon: Type,
            tooltip: 'Document editing'
        },
        {
            id: PANEL_TYPES.NODES,
            icon: Cpu,
            tooltip: 'Manage nodes'
        },
        {
            id: PANEL_TYPES.EDGES,
            icon: Share2,
            tooltip: 'Manage connections'
        }
    ];

    return (
        <div className="tabs-container regular-tabs">
            {TABS.map(({ id, icon: Icon, tooltip }) => (
                <Button
                    key={id}
                    variant={activeTab === id ? 'default' : 'ghost'}
                    size="icon"
                    onClick={() => setActiveTab(id)}
                    className="tab-button"
                    title={tooltip}
                >
                    <Icon className="w-5 h-5" />
                </Button>
            ))}
        </div>
    );
};

RegularTabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
};

export default RegularTabs;