// ActionButtons.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
    Save,
    Download,
    Upload,
    Share2,
    Copy,
    Settings,
    FileJson,
    FileText,
    LogOut,
    FileEdit,
    Pencil
} from 'lucide-react';
import './Tabs.css';
import { Button } from '../../../../App/components/ui/button.jsx'; // Ensure Button is correctly imported
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem
} from '../../../../App/components/ui/dropdown-menu.js'; // Ensure DropdownMenu components are imported

const ActionButtons = ({
                           onSave,
                           onExport,
                           onImport,
                           onShare,
                           onCopy,
                           onSettings,
                           onLogout,
                           onNewDiagram,
                           onToggleView, // Changed from setActiveView to onToggleView
                           activeView,
                           hasChanges = false,
                           isProcessing = false // Ensure isProcessing has a default value
                       }) => {
    const secondaryActions = [
        {
            icon: Share2,
            tooltip: 'Share',
            onClick: onShare
        },
        {
            icon: Copy,
            tooltip: 'Copy',
            onClick: onCopy
        },
        {
            icon: Pencil,
            tooltip: activeView === 'document' ? 'Switch to Diagram' : 'Switch to Document',
            onClick: onToggleView // Use the passed callback directly
        },
        {
            icon: Settings,
            tooltip: 'Settings',
            onClick: onSettings
        }
    ];

    const primaryActions = [
        {
            icon: Save,
            tooltip: 'Save',
            onClick: onSave,
            disabled: !hasChanges,
            primary: true
        },
        {
            icon: Download,
            tooltip: 'Export',
            isDropdown: true,
            items: [
                { icon: FileJson, tooltip: 'Export as JSON', onClick: () => onExport('json') },
                { icon: FileText, tooltip: 'Export as Text', onClick: () => onExport('text') }
            ]
        },
        {
            icon: Upload,
            tooltip: 'Import',
            onClick: onImport
        }
    ];

    const tertiaryActions = [
        {
            icon: FileEdit,
            tooltip: 'New Diagram',
            onClick: onNewDiagram,
            primary: true
        },
        {
            icon: LogOut,
            tooltip: 'Logout',
            onClick: onLogout,
            variant: 'destructive'
        }
    ];

    const renderAction = (action) => {
        if (action.isDropdown) {
            return (
                <DropdownMenu key={action.tooltip}>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="outline"
                            size="icon"
                            disabled={isProcessing}
                            className="action-button"
                            title={action.tooltip}
                        >
                            <action.icon className="w-5 h-5" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {action.items.map((item) => (
                            <DropdownMenuItem
                                key={item.tooltip}
                                onClick={item.onClick}
                                className="dropdown-item"
                                title={item.tooltip}
                            >
                                <item.icon className="w-4 h-4" />
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        }

        return (
            <Button
                key={action.tooltip}
                variant={action.variant || (action.primary ? 'default' : 'outline')}
                size="icon"
                onClick={action.onClick}
                disabled={isProcessing || action.disabled}
                className="action-button"
                title={action.tooltip}
            >
                <action.icon className="w-5 h-5" />
            </Button>
        );
    };

    return (
        <div className="action-buttons-container">
            <div className="primary-actions">
                {primaryActions.map(renderAction)}
            </div>
            <div className="secondary-actions">
                {secondaryActions.map(renderAction)}
            </div>
            <div className="tertiary-actions">
                {tertiaryActions.map(renderAction)}
            </div>
        </div>
    );
};

ActionButtons.propTypes = {
    onSave: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onSettings: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onNewDiagram: PropTypes.func.isRequired,
    onToggleView: PropTypes.func.isRequired, // Changed from setActiveView
    activeView: PropTypes.oneOf(['document', 'diagram']).isRequired,
    isProcessing: PropTypes.bool,
    hasChanges: PropTypes.bool
};

export default ActionButtons;
