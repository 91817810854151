import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    RefreshCw,
    AlertCircle,
    CheckCircle,
    PenTool,
    Coins
} from 'lucide-react';
import { Alert, AlertDescription } from '../App/components/ui/alert.jsx';
import { AppContext } from '../App/context/AppContext.js';
import { usePanel } from './hooks/usePanel.js';
import { apiService } from '../Services/api/axiosConfig.js';
import ActionButtons from './components/Navigator/Tabs/ActionButtons.js';
import RegularTabs from './components/Navigator/Tabs/RegularTabs.js';
import AITabs from './components/Navigator/Tabs/AITabs.js';
import InputPanel from './components/Navigator/Panels/InputPanel/InputPanel.js';
import NodesPanel from './components/Navigator/Panels/NodesPanel/NodesPanel.js';
import EdgesPanel from './components/Navigator/Panels/EdgesPanel/EdgesPanel.js';
import CharacteristicsPanel from './components/Navigator/Panels/CharacteristicsPanel/CharacteristicsPanel.js';
import ResponsesPanel from './components/Navigator/Panels/ResponsesPanel/ResponsesPanel.js';
import WritingPanel from './components/Navigator/Panels/WritingPanel/WritingPanel.js';
import Draggable from 'react-draggable';
import {
    PANEL_TYPES,
    SUCCESS_MESSAGES,
    ERROR_MESSAGES,
    MESSAGE_TYPES,
    RESPONSE_PROVIDERS,
    VIEW_TYPES,
    DEFAULT_STATES,
    NODE_TYPES,
    EDGE_TYPES,
    FILE_TYPES
} from './constants/constants.js';
import './RightPanel.css';

function RightPanel({
                        className = '',
                        onFloatingChange,
                        activeView,
                        onViewChange,
                        isExpanded,
                        onExpandChange,
                        diagramInstance,
                    }) {
    const {
        token,
        tokenBalance,
        userSettings,
        setUserSettings,
        isAIEnabled,
        logout
    } = useContext(AppContext);

    const {
        activeTab,
        setActiveTab,
        isFloating,
        isLoading,
        setLoading,
        error,
        showError,
        successMessage,
        showSuccess,
        nodes,
        setNodes,
        edges,
        setEdges,
        selectedDiagram,
        setSelectedDiagram,
        selectedEdge,
        setSelectedEdge,
        messageGroups,
        updateMessageGroups,
        setActiveResponseGroup,
        addAIResponse,
        markUnsavedChanges,
        clearUnsavedChanges,
        position,
        setPosition,
    } = usePanel();

    // Save Handler
    const handleSave = useCallback(async () => {
        try {
            await handleSaveDiagram();
            showSuccess('Changes saved successfully');
            clearUnsavedChanges();
        } catch (error) {
            showError('Failed to save changes');
        }
    }, [clearUnsavedChanges, showError, showSuccess]);

    // Drag and Drop Handlers
    const handleDragStop = useCallback((e, data) => {
        setPosition({ x: data.x, y: data.y });
    }, [setPosition]);

    // Panel Control Handlers
    const handleExpand = useCallback(() => {
        onExpandChange(!isExpanded);
    }, [isExpanded, onExpandChange]);

    const handleViewChange = useCallback(() => {
        onViewChange(activeView === VIEW_TYPES.DOCUMENT ? VIEW_TYPES.DIAGRAM : VIEW_TYPES.DOCUMENT);
    }, [activeView, onViewChange]);

    const handleFloatingChange = useCallback(() => {
        onFloatingChange(!isFloating);
    }, [isFloating, onFloatingChange]);

    const handleSettings = useCallback(() => {
        setActiveTab(PANEL_TYPES.CHARACTERISTICS);
    }, [setActiveTab]);

    const handleNewDiagram = useCallback(() => {
        setNodes([]);
        setEdges([]);
        setSelectedDiagram(null);
        markUnsavedChanges();
    }, [setNodes, setEdges, setSelectedDiagram, markUnsavedChanges]);

    // Response Formatting
    const formatResponse = useCallback((response, provider, timestamp) => ({
        id: `${provider.toLowerCase()}-${Date.now()}`,
        role: MESSAGE_TYPES.ASSISTANT,
        content: response.content,
        provider,
        model: response.model || 'Unknown Model',
        timestamp,
        metadata: {
            tokensUsed: response.usage?.total_tokens || 0,
            latency: response.latency || 0,
        }
    }), []);

    // AI Submit Handler
    const handleSubmit = useCallback(async (message) => {
        if (isLoading) return;
        setLoading(true);

        const timestamp = new Date().toISOString();
        const messageId = `msg-${Date.now()}`;

        try {
            const userMessage = {
                id: `user-${Date.now()}`,
                role: MESSAGE_TYPES.USER,
                content: message.content,
                timestamp,
                messageGroupId: messageId
            };

            const newMessageGroup = {
                id: messageId,
                timestamp,
                userMessage,
                responses: []
            };

            const response = await apiService.responses.create({
                messages: [message],
                timestamp
            });

            const responses = [];

            if (response.anthropic) {
                responses.push(formatResponse(response.anthropic, RESPONSE_PROVIDERS.CLAUDE, timestamp));
            }

            if (response.openai) {
                responses.push(formatResponse(response.openai, RESPONSE_PROVIDERS.OPENAI, timestamp));
            }

            newMessageGroup.responses = responses;

            updateMessageGroups(new Map([[messageId, newMessageGroup]]));
            responses.forEach(addAIResponse);
            setActiveResponseGroup(messageId);
            setActiveTab(PANEL_TYPES.RESPONSES);
            showSuccess(SUCCESS_MESSAGES.RESPONSE_SUCCESS);

        } catch (error) {
            console.error('Input processing error:', error);
            showError(ERROR_MESSAGES.API_ERROR);
        } finally {
            setLoading(false);
        }
    }, [isLoading, formatResponse, setLoading, updateMessageGroups, addAIResponse, setActiveResponseGroup, setActiveTab, showSuccess, showError]);

    // Response Handlers
    const handleShareResponse = useCallback(async (response) => {
        try {
            await apiService.responses.share(response);
            showSuccess(SUCCESS_MESSAGES.SHARE_SUCCESS);
        } catch (error) {
            showError(ERROR_MESSAGES.SHARE_FAILED);
        }
    }, [showSuccess, showError]);

    const handleCopy = useCallback(async (content) => {
        try {
            await navigator.clipboard.writeText(content);
            showSuccess(SUCCESS_MESSAGES.COPY_SUCCESS);
        } catch (error) {
            showError(ERROR_MESSAGES.COPY_FAILED);
        }
    }, [showSuccess, showError]);

    const handleWinnerSelection = useCallback((groupId, winner) => {
        const group = messageGroups.get(groupId);
        if (group) {
            updateMessageGroups(new Map([[groupId, { ...group, winner }]]));
        }
    }, [messageGroups, updateMessageGroups]);

    // Node Handlers
    const handleAddNode = useCallback((type, position = { x: 100, y: 100 }) => {
        const newNode = {
            id: `node-${Date.now()}`,
            type: type || NODE_TYPES.DEFAULT,
            position,
            data: {
                label: `New ${type || NODE_TYPES.DEFAULT} Node`,
                type: type || NODE_TYPES.DEFAULT,
            },
        };
        setNodes(prev => [...prev, newNode]);
        markUnsavedChanges();
    }, [setNodes, markUnsavedChanges]);

    const handleUpdateNode = useCallback((id, data) => {
        setNodes(prev =>
            prev.map(node => (node.id === id ? { ...node, ...data } : node))
        );
        markUnsavedChanges();
    }, [setNodes, markUnsavedChanges]);

    const handleDeleteNode = useCallback((id) => {
        setNodes(prev => prev.filter(node => node.id !== id));
        setEdges(prev =>
            prev.filter(edge => edge.source !== id && edge.target !== id)
        );
        markUnsavedChanges();
    }, [setNodes, setEdges, markUnsavedChanges]);

    // Edge Handlers
    const handleEdgeTypeSelect = useCallback((type) => {
        if (selectedEdge && diagramInstance?.updateEdge) {
            diagramInstance.updateEdge(selectedEdge.id, {
                ...selectedEdge,
                type,
                animated: type === EDGE_TYPES.STEP,
            });
            markUnsavedChanges();
        }
    }, [selectedEdge, diagramInstance, markUnsavedChanges]);

    // Save/Export/Import Handlers
    const handleSaveDiagram = useCallback(async () => {
        setLoading(true);
        try {
            const diagramData = {
                ...selectedDiagram,
                nodes,
                edges,
                lastModified: new Date().toISOString(),
            };

            if (!selectedDiagram?.id) {
                localStorage.setItem('diagram_data', JSON.stringify(diagramData));
            } else {
                const response = await apiService.diagrams.update(
                    selectedDiagram.id,
                    diagramData
                );
                if (response?.data) {
                    setSelectedDiagram(response.data);
                }
            }

            clearUnsavedChanges();
            showSuccess(SUCCESS_MESSAGES.SAVE_SUCCESS);
        } catch (error) {
            showError(ERROR_MESSAGES.SAVE_FAILED);
        } finally {
            setLoading(false);
        }
    }, [selectedDiagram, nodes, edges, setLoading, setSelectedDiagram, clearUnsavedChanges, showSuccess, showError]);

    const handleExportDiagram = useCallback(() => {
        try {
            const diagramData = {
                name: selectedDiagram?.name || 'Untitled Diagram',
                nodes,
                edges,
                settings: userSettings,
                exportDate: new Date().toISOString(),
            };
            const blob = new Blob([JSON.stringify(diagramData, null, 2)], {
                type: FILE_TYPES.JSON,
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${diagramData.name.toLowerCase().replace(/\s+/g, '-')}.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            showSuccess(SUCCESS_MESSAGES.EXPORT_SUCCESS);
        } catch (error) {
            showError(ERROR_MESSAGES.EXPORT_FAILED);
        }
    }, [selectedDiagram, nodes, edges, userSettings, showSuccess, showError]);

    const handleImportDiagram = useCallback((event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const importedData = JSON.parse(e.target?.result);
                setNodes(importedData.nodes || []);
                setEdges(importedData.edges || []);
                if (importedData.settings) {
                    setUserSettings(prev => ({
                        ...prev,
                        ...importedData.settings,
                    }));
                }
                showSuccess(SUCCESS_MESSAGES.IMPORT_SUCCESS);
                markUnsavedChanges();
            } catch (error) {
                showError(ERROR_MESSAGES.IMPORT_FAILED);
            }
        };
        reader.readAsText(file);
    }, [setNodes, setEdges, setUserSettings, showSuccess, showError, markUnsavedChanges]);

    // Panel Content Renderer
    const renderPanel = useCallback(() => {
        switch (activeTab) {
            case PANEL_TYPES.WRITING:
                return (
                    <WritingPanel
                        onFormatChange={() => markUnsavedChanges()}
                        currentFormats={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '12',
                            bold: false,
                            italic: false,
                            underline: false,
                            align: 'left',
                        }}
                    />
                );

            case PANEL_TYPES.INPUT:
                return token ? (
                    <InputPanel
                        onSubmit={handleSubmit}
                        isLoading={isLoading}
                        tokenBalance={tokenBalance}
                        isAIEnabled={true}
                    />
                ) : null;

            case PANEL_TYPES.NODES:
                return (
                    <NodesPanel
                        nodeTypes={NODE_TYPES}
                        onAddNode={handleAddNode}
                        onUpdateNode={handleUpdateNode}
                        onDeleteNode={handleDeleteNode}
                    />
                );

            case PANEL_TYPES.EDGES:
                return (
                    <EdgesPanel
                        edges={diagramInstance?.getEdges() || []}
                        nodes={nodes}
                        selectedEdge={selectedEdge}
                        onSelectEdge={setSelectedEdge}
                        onAddEdge={(params) => {
                            if (diagramInstance?.addEdge) {
                                diagramInstance.addEdge(params);
                                markUnsavedChanges();
                            }
                        }}
                        onUpdateEdge={(id, data) => {
                            if (diagramInstance?.updateEdge) {
                                diagramInstance.updateEdge(id, data);
                                markUnsavedChanges();
                            }
                        }}
                        onDeleteEdge={(id) => {
                            if (diagramInstance?.deleteEdge) {
                                diagramInstance.deleteEdge(id);
                                setSelectedEdge(null);
                                markUnsavedChanges();
                            }
                        }}
                        onEdgeTypeSelect={handleEdgeTypeSelect}
                    />
                );

            case PANEL_TYPES.CHARACTERISTICS:
                return token ? (
                    <CharacteristicsPanel
                        onChange={(newSliders) => {
                            setUserSettings(prev => ({
                                ...prev,
                                aiCharacteristics: newSliders,
                            }));
                            markUnsavedChanges();
                        }}
                        sliders={userSettings?.aiCharacteristics || DEFAULT_STATES.sliders}
                    />
                ) : null;

            case PANEL_TYPES.RESPONSES:
                return token ? (
                    <ResponsesPanel
                        responses={Array.from(messageGroups.values()).flatMap(group => [
                            group.userMessage,
                            ...group.responses
                        ])}
                        onNewPrompt={() => setActiveTab(PANEL_TYPES.INPUT)}
                        error={error}
                        onShare={handleShareResponse}
                        onCopy={handleCopy}
                        onWinnerSelected={(timestamp, winner) => {
                            const group = Array.from(messageGroups.values()).find(
                                g => g.timestamp.split('T')[0] === timestamp
                            );
                            if (group) {
                                handleWinnerSelection(group.id, winner);
                            }
                        }}
                    />
                ) : null;

            default:
                return null;
        }
    }, [
        activeTab,
        token,
        handleSubmit,
        isLoading,
        tokenBalance,
        nodes,
        diagramInstance,
        selectedEdge,
        handleEdgeTypeSelect,
        userSettings,
        messageGroups,
        error,
        handleShareResponse,
        handleCopy,
        handleWinnerSelection,
        setUserSettings,
        markUnsavedChanges,
        setSelectedEdge,
        setActiveTab
    ]);

    const panelContent = (
        <div className={`right-panel ${className} ${isFloating ? 'floating' : ''}`}>
            <div className="navigator">
                <div className="navigator-content">
                    {token && (
                        <div className="token-display">
                            <div className="token-balance">
                                <Coins className="w-4 h-4" />
                                {tokenBalance} tokens remaining
                            </div>
                        </div>
                    )}

                    <ActionButtons
                        onSave={handleSave}
                        onExport={handleExportDiagram}
                        onImport={handleImportDiagram}
                        onShare={handleShareResponse}
                        onCopy={handleCopy}
                        onSettings={handleSettings}
                        onLogout={logout}
                        onNewDiagram={handleNewDiagram}
                        isProcessing={isLoading}
                        selectedDiagram={selectedDiagram}
                        hasChanges={nodes.length > 0 || edges.length > 0}
                        onFloatingChange={handleFloatingChange}
                        activeView={activeView}
                        onToggleView={handleViewChange}
                        isExpanded={isExpanded}
                        onExpandChange={handleExpand}
                        iconOnly={true}
                    />

                    <div className="tabs-section">
                        <RegularTabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {token && isAIEnabled && (
                            <AITabs
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        )}
                    </div>
                </div>
            </div>

            {error && (
                <Alert variant="destructive" className="m-4">
                    <AlertCircle className="w-4 h-4 mr-2" />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            {successMessage && (
                <Alert variant="success" className="m-4">
                    <CheckCircle className="w-4 h-4 mr-2" />
                    <AlertDescription>{successMessage}</AlertDescription>
                </Alert>
            )}

            <div className="panel-content">
                {renderPanel()}
            </div>

            <div className="panel-footer">
                <div className="footer-content">
                    <div className="diagram-info">
                        <PenTool className="icon" />
                        <span className="diagram-name">
                            {selectedDiagram?.name || 'New Diagram'}
                        </span>
                        {nodes.length > 0 && (
                            <span className="diagram-stats">
                                ({nodes.length} nodes, {edges.length} edges)
                            </span>
                        )}
                    </div>
                </div>
            </div>

            {isLoading && (
                <div className="loading-overlay">
                    <RefreshCw className="animate-spin loading-icon" />
                    <span>Processing...</span>
                </div>
            )}
        </div>
    );

    const dragHandlers = {
        handle: ".panel-header",
        bounds: "parent",
        position,
        onStop: handleDragStop,
        defaultClassName: "floating-panel-wrapper"
    };

    return isFloating ? <Draggable {...dragHandlers}>{panelContent}</Draggable> : panelContent;
}

RightPanel.propTypes = {
    className: PropTypes.string,
    onFloatingChange: PropTypes.func.isRequired,
    activeView: PropTypes.oneOf(Object.values(VIEW_TYPES)).isRequired,
    onViewChange: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onExpandChange: PropTypes.func.isRequired,
    diagramInstance: PropTypes.shape({
        getEdges: PropTypes.func,
        addEdge: PropTypes.func,
        updateEdge: PropTypes.func,
        deleteEdge: PropTypes.func,
    }),
};

export default RightPanel;